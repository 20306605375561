import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const WhatWeDo = () => {
    const seo = {
        metaDesc: 'What We Do - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'about'}>
            <SEO title="What We Do - Inventiv.org" canonical='/what-we-do' seo={seo} />
            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>What We Do</h1>
                    </div>
                </div>
            </div>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <p class="f-16 sec-clr">
                                At Inventiv.org, we are driven by a singular purpose: to ignite innovation and empower inventors to make a lasting impact. We believe that every great invention starts with a spark of inspiration and is nurtured through knowledge, collaboration, and support. That’s why we have built a vibrant ecosystem where inventors thrive and ideas flourish.
                                <br />
                                <br />
                                Education is at the heart of what we do. We provide a diverse range of learning opportunities, from online courses and workshops to hands-on experiences and immersive programs. Our aim is to equip inventors with the skills, insights, and resources they need to bring their ideas to life. Through our educational initiatives, we foster a culture of continuous learning, fuel curiosity, and cultivate the next generation of inventors.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <img src="assets/img/banner/two-colleague-working-together.webp" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3 bg-light-blue what-we-do-sec-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <div class="img-1 text-center mb-1">
                                <img src="assets/img/banner/man-showing-graph-to-woman.webp" width="80%" alt="" />
                            </div>
                            <div class="img-2 text-center">
                                <img src="assets/img/banner/business-woman-working-on-her-laptop.webp" width="80%" alt="" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="f-16 sec-clr">
                                But we don’t stop at education. We believe that inventors thrive when they have a supportive software and community behind them. That's why we have built free DIY software such as ProvisionalBuilder ® and a global network of mentors, experts, and fellow inventors who are ready to offer guidance, share experiences, and provide valuable feedback. Our mentorship programs and networking events create spaces for meaningful connections, collaborations, and the exchange of ideas.
                                <br />
                                <br />
                                We also understand that turning an invention into a reality often requires resources and funding. That's why we build our network of incubators to help inventors advance their projects and take their ideas to the next level. We believe in investing in inventors who have the potential to create transformative solutions that address pressing global challenges.
                                <br /><br />
                                In addition to nurturing individual inventors, we foster a culture of collaboration and innovation through our partnerships and initiatives. We bring together inventors, organizations, and communities to tackle complex problems and drive systemic change. By facilitating interdisciplinary collaborations and providing platforms for collective action, we catalyze the power of invention to address the most urgent issues of our time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <p class="f-16 sec-clr">
                                Through our work, we are dedicated to creating a world where innovation flourishes and inventors are celebrated as catalysts for progress. We believe that by embracing the power of invention, we can unlock breakthrough solutions that transform industries, protect our planet, and improve lives.
                                <br />
                                <br />
                                Join us at Inventiv.org and embark on a journey of discovery, creativity, and impact. Together, let's unleash the inventors within us, push the boundaries of what’s possible, and shape a future that is driven by innovation and driven by positive change.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <img src="assets/img/banner/patent-work.webp" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default WhatWeDo;